body {
  background-color: white;
  width: 100%;
  margin: 0 auto;
  left: 0;
  font-family: system-ui, -apple-system, "Segoe UI", "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media (max-width: 1600px) {
  body {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  body {
    font-size: 0.28rem;
  }
}
/* Media query abbreviation */
:root {
  --header-height: 80px;
  /* COLOR */
  --color-active: #2280c1;
  --color-red: #f63a3c;
  --color-white: #fff;
  --color-black-000: #000000;
  --color-black: #212529;
  --color-gray-f7: #f7f7f7;
  --color-gray-74: #74787c;
  --color-gray-f4: #f4f4f4;
  --color-gray-a2: #a2a2a2;
  --color-gray-8c: #8C9095;
  --color-gray-eee: #eee;
  --color-gray-ddd: #ddd;
  --color-gray-bbb: #bbb;
  --color-gray-999: #999;
  --color-gray-666: #666;
  --color-gray-444: #444;
  --color-gray-333: #333;
  --color-bg-f6: #F6F7FB;
  --color-bg-f9: #F9FAFC;
  --color-bg-f5: #f5f5f5;
  /* font size */
  --font12: 12px;
  --font14: 14px;
  --font16: 16px;
  --font18: 18px;
  --font20: 20px;
  --font22: 22px;
  --font24: 24px;
  --font26: 26px;
  --font28: 28px;
  --font30: 30px;
  --font32: 32px;
  --font34: 34px;
  --font36: 36px;
  --font38: 38px;
  --font40: 40px;
  --font42: 42px;
  --font44: 44px;
  --font46: 46px;
  --font48: 48px;
  --font50: 50px;
  --font52: 52px;
  --font54: 54px;
  --font56: 56px;
  --font58: 58px;
  --font60: 60px;
  --font62: 62px;
  --font64: 64px;
  --font66: 66px;
  --font68: 68px;
  --font72: 72px;
  --font100: 100px;
  /* border-radius */
  --border-radius10: 5px;
  --border-radius20: 20px;
}
@media (max-width:1840px) {
  :root {
    /* font size */
    --font12: 11px;
    --font14: 13px;
    --font16: 14px;
    --font18: 16px;
    --font20: 18px;
    --font22: 20px;
    --font24: 22px;
    --font26: 24px;
    --font28: 26px;
    --font30: 28px;
    --font32: 30px;
    --font34: 32px;
    --font36: 34px;
    --font38: 36px;
    --font40: 38px;
    --font42: 40px;
    --font44: 40px;
    --font46: 40px;
    --font48: 40px;
    --font50: 46px;
    --font52: 48px;
    --font54: 48px;
    --font56: 48px;
    --font58: 48px;
    --font60: 48px;
    --font62: 48px;
    --font64: 48px;
    --font68: 48px;
    --font72: 48px;
    --font100: 48px;
  }
}
@media (max-width:1560px) {
  :root {
    /* font size */
    --font12: 11px;
    --font14: 13px;
    --font16: 14px;
    --font18: 15px;
    --font20: 17px;
    --font22: 18px;
    --font24: 20px;
    --font26: 22px;
    --font28: 24px;
    --font30: 24px;
    --font32: 26px;
    --font34: 26px;
    --font36: 28px;
    --font38: 28px;
    --font40: 30px;
    --font42: 32px;
    --font44: 32px;
    --font46: 32px;
    --font48: 34px;
    --font50: 34px;
    --font52: 34px;
    --font54: 36px;
    --font56: 38px;
    --font58: 38px;
    --font60: 44px;
    --font62: 44px;
    --font64: 44px;
    --font68: 44px;
    --font72: 44px;
    --font100: 44px;
  }
}
@media (max-width:768px) {
  :root {
    /* font size */
    --font14: 0.24rem;
    --font16: 0.24rem;
    --font18: 0.3rem;
    --font20: 0.3rem;
    --font22: 0.31rem;
    --font24: 0.34rem;
    --font26: 0.34rem;
    --font28: 0.34rem;
    --font30: 0.36rem;
    --font32: 0.36rem;
    --font34: 0.36rem;
    --font36: 0.38rem;
    --font38: 0.38rem;
    --font40: 0.38rem;
    --font42: 0.38rem;
    --font44: 0.38rem;
    --font46: 0.38rem;
    --font48: 0.42rem;
    --font50: 0.42rem;
    --font52: 0.42rem;
    --font54: 0.42rem;
    --font56: 0.42rem;
    --font58: 0.42rem;
    --font60: 0.42rem;
    --font62: 0.42rem;
    --font64: 0.42rem;
    --font66: 0.42rem;
    --font68: 0.55rem;
    --font100: 0.55rem;
  }
}
html,
body,
div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
main,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
input,
textarea,
select,
button,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: initial;
  vertical-align: baseline;
  background: transparent;
  -webkit-tap-highlight-color: transparent;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section {
  display: block;
}
.font-w600 {
  font-weight: 600;
}
.font-w500 {
  font-weight: 500;
}
.font-w400 {
  font-weight: 400;
}
.font-w300 {
  font-weight: 300;
}
.font-w200 {
  font-weight: 200;
}
.transition {
  transition: all 0.45s ease-in-out;
}
p,
span {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  -webkit-tap-highlight-color: transparent;
}
h1,
h2,
h3,
h4,
h5,
h6,
em,
i {
  font-weight: normal;
  font-style: normal;
}
ul,
ol,
li {
  list-style-type: none;
}
a {
  text-decoration: none;
  outline: none;
  color: #000;
  cursor: pointer;
}
a:focus {
  outline: none;
  -moz-outline: none;
}
a img {
  border: none;
}
select,
input,
textarea {
  border-radius: 0;
  -webkit-border-radius: 0;
}
input:focus {
  outline: none;
}
input,
button,
select,
textarea {
  outline: none;
}
textarea {
  resize: none;
}
textarea,
input,
select {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}
table {
  background-color: transparent;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  border-top: solid 1px #ddd;
  border-left: solid 1px #ddd;
  box-sizing: border-box;
}
table th {
  background-color: #f9f9f9;
  text-align: center;
  box-sizing: border-box;
}
table td,
table th {
  padding: 5px 10px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}
main,
footer {
  display: block;
}
input[type="button"],
input[type="submit"],
input[type="file"],
button {
  cursor: pointer;
  -webkit-appearance: none;
}
.clearfix:after {
  clear: both;
  display: block;
  content: "";
  height: 0;
  visibility: hidden;
}
.clearfix {
  zoom: 1;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.fleximportant {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
}
.inlineflex {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
}
.d_flex > * {
  display: block;
}
.f_column {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.f_row {
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  -o-flex-direction: row;
  flex-direction: row;
}
.j_center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.j_end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.j_start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.j_justify {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.j_around {
  -moz-justify-content: space-around;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.a_start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.a_end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.a_center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.a_baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}
.a_stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
}
.flex_wrap {
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}
.flex_auto {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  -webkit-flex: auto;
  flex: auto;
}
.flex_1 {
  width: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;
  flex: 1;
}
.order_2 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
}
.order_3 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 3;
  -webkit-order: 3;
  order: 3;
}
.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dh {
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.fd {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}
.imgfd {
  -webkit-transform: translate(-50%, -50%) scale(1.05);
  -ms-transform: translate(-50%, -50%) scale(1.05);
  -o-transform: translate(-50%, -50%) scale(1.05);
  transform: translate(-50%, -50%) scale(1.05);
}
.xzx {
  transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  -webkit-transform: rotateX(180deg);
  -o-transform: rotateX(180deg);
}
.xzy {
  transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
}
.pb {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}
.ab {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  text-align: center;
}
.abimg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}
.pc {
  display: block;
}
.wap,
.mobile {
  display: none;
}
@media (max-width:990px) {
  .pc {
    display: none;
  }
  .wap,
  .mobile {
    display: block;
  }
}
.ones-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.twos-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.clearfix:after,
.clearfix:before {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  zoom: 1;
}
.picture {
  /*position: relative;*/
  overflow: hidden;
  /*z-index: 10;*/
}
.picture img {
  transition: all 0.7s;
  -moz-transition: all 0.7s;
  -webkit-transition: all 0.7s;
}
.each-item:hover .picture img {
  transform: scale(1.03);
  -moz-transform: scale(1.03);
  -webkit-transform: scale(1.03);
  zoom: 1.03 \9;
}
/*flex*/
.flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-hc {
  -webkit-box-pack: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.flex-zBetween {
  -webkit-box-pack: justify;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-zEnd {
  -webkit-box-pack: end;
  -moz-justify-content: flex-end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.flex-zStart {
  -webkit-box-pack: start;
  -moz-justify-content: flex-start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.flex-ac {
  -webkit-box-align: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  align-items: center;
}
.flex-cStart {
  -webkit-box-align: start;
  -moz-align-items: flex-start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.flex-cEnd {
  -webkit-box-align: end;
  -moz-align-items: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.flex-cBaseline {
  -webkit-box-align: baseline;
  -moz-align-items: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}
.flex-cStretch {
  -webkit-box-align: stretch;
  -moz-align-items: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
}
.flex-zTopBottom {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-zBottomTop {
  -webkit-box-pack: end;
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -moz-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.flex-zLeftRight {
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.flex-zRightLeft {
  -webkit-box-pack: end;
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -moz-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.flex-shrink {
  -webkit-box-flex: 0;
  -moz-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.flex-center {
  -webkit-align-content: center;
  align-content: center;
}
.flex-start {
  -webkit-align-content: flex-start;
  align-content: flex-start;
}
.flex-end {
  -webkit-align-content: flex-end;
  align-content: flex-end;
}
.flex-between {
  -webkit-align-content: space-between;
  align-content: space-between;
}
.flex-container {
  -webkit-align-content: space-around;
  align-content: space-around;
}
.flex-wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex-nowrap {
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -o-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.flex_bd {
  -prefix-box-flex: 1;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
html {
  font-size: calc(100vw / 19.2);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width:1200px) {
  html {
    font-size: calc(100vw / 12);
  }
}
@media (max-width:768px) {
  html {
    font-size: calc(100vw / 7.8);
  }
}
img {
  max-width: 100%;
  border: 0;
  vertical-align: top;
}
li {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: normal;
}
a,
a:hover,
a:focus {
  text-decoration: none;
}
input,
button {
  -webkit-appearance: none;
  border-radius: 0;
}
button {
  cursor: pointer;
}
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
textarea {
  overflow: auto;
}
input,
button,
textarea,
select {
  border: 0;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: normal;
  color: inherit;
  background: transparent;
}
select {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  white-space: nowrap;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select::-ms-expand {
  display: none;
}
table {
  border-collapse: collapse;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.clear {
  zoom: 1;
}
.clear:after {
  content: '';
  display: block;
  clear: both;
}
.font14 {
  font-size: 0.14rem;
}
@media (max-width:990px) {
  .font14 {
    font-size: 0.28rem;
  }
}
.font16 {
  font-size: 0.16rem;
}
@media (max-width:990px) {
  .font16 {
    font-size: 0.28rem;
  }
}
.font18 {
  font-size: 0.18rem;
}
@media (max-width:990px) {
  .font18 {
    font-size: 0.3rem;
  }
}
.font20 {
  font-size: 0.2rem;
}
@media (max-width:990px) {
  .font20 {
    font-size: 0.31rem;
  }
}
.font24 {
  font-size: 0.24rem;
}
@media (max-width:990px) {
  .font24 {
    font-size: 0.34rem;
  }
}
.font42 {
  font-size: 0.42rem;
}
@media (max-width:990px) {
  .font42 {
    font-size: 0.46rem;
  }
}
.public-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.2rem;
  min-width: 1rem;
}
.public-img {
  display: block;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.public-img::before {
  content: "";
  display: block;
  position: relative;
  z-index: 0;
  padding-top: 100%;
}
.public-img > img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s;
}
#hi-upgrade {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: #fff;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
}
#hi-upgrade .hi-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -177px 0 0 -450px;
  width: 900px;
}
#hi-upgrade .hi-wrap .hi-title {
  font-size: 30px;
  line-height: 40px;
  color: #333;
}
#hi-upgrade .hi-wrap .hi-close {
  margin: 10px 0;
  display: inline-block;
  cursor: pointer;
  font-size: 18px;
  color: var(--color-active);
}
#hi-upgrade .hi-wrap .hi-close:hover {
  text-decoration: underline;
}
#hi-upgrade .hi-wrap .hi-text1 {
  color: #666;
}
#hi-upgrade .hi-wrap .hi-text1 span {
  color: var(--color-active);
}
#hi-upgrade .hi-wrap .hi-text2 {
  position: relative;
  margin: 60px 0;
  color: #333;
}
#hi-upgrade .hi-wrap .hi-text2::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  width: 100%;
  border-top: 1px solid #ddd;
}
#hi-upgrade .hi-wrap .hi-text2 span {
  position: relative;
  z-index: 5;
  padding: 0 30px;
  background: #fff;
}
#hi-upgrade .hi-wrap .hi-list {
  overflow: hidden;
}
#hi-upgrade .hi-wrap .hi-list li {
  display: inline-block;
  width: 16%;
}
#hi-upgrade .hi-wrap .hi-list li div {
  margin: 0 auto 5px;
  width: 50px;
  height: 50px;
}
#hi-upgrade .hi-wrap .hi-list li a {
  color: #666;
}
#hi-upgrade .hi-wrap .hi-list li a:hover {
  color: var(--color-active);
}
.main {
  position: relative;
  height: 89vh;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding-top: 8vh;
}
@media (max-width: 768px) {
  .main {
    height: auto;
    overflow: hidden;
    box-sizing: border-box;
    padding-bottom: 30px;
  }
}
.ant-modal-confirm.ant-modal-confirm-success {
  margin: 22vh auto 0;
}
.res-list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: end;
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -moz-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.input-con-box {
  width: 90%;
  max-width: 650px;
  margin: auto;
}
.input-con-box .button-con {
  padding: 25px 0;
}
@media (max-width: 768px) {
  .input-con-box .button-con {
    padding: 18px 0 0;
    display: block;
  }
}
.input-con-box .button-con .button {
  width: 160px;
  height: 42px;
  display: block;
  background: #f8f9fa;
  color: #000000;
  margin: 0 5px;
  cursor: pointer;
  box-sizing: border-box;
  line-height: 42px;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #f8f9fa;
}
@media (max-width: 768px) {
  .input-con-box .button-con .button {
    line-height: 40px;
    height: 40px;
    border: 0;
    width: 100%;
    border-radius: 24px;
    margin: 0 0 10px 0;
  }
}
.input-con-box .button-con .button:hover {
  background-color: #f8f9fa;
  /*border-color: rgba(0, 0, 0, 0.6);*/
}
.input-con-box .input-con {
  display: flex;
  z-index: 3;
  position: relative;
  min-height: 44px;
  background: #fff;
  border: 1px solid #dfe1e5;
  box-shadow: none;
  border-radius: 24px;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .input-con-box .input-con {
    min-height: 38px;
  }
}
.input-con-box .input-con:hover,
.input-con-box .input-con.focusInput {
  border-color: rgba(0, 0, 0, 0.6);
}
.input-con-box .input-con .icon-search {
  width: 25px;
  height: 25px;
  padding-left: 10px;
}
.input-con-box .input-con .icon-search path{
  fill: #9aa0a6;
}
@media (max-width: 1024px) {
  .input-con-box .input-con .icon-search {
    height: 25px;
    width: 25px;
  }
}
.input-con-box .input-con input::placeholder,
.input-con-box .input-con textarea::placeholder {
  color: #e9ecef;
}
.input-con-box .input-con form{
  width: 100%;
}
.input-con-box .input-con input {
  font-size: 16px;
  color: #000000;
  min-height: 44px;
  width: calc(100% - 50px);
}
input[type="search"]{-webkit-appearance:none;}
input[type="search"]::-webkit-search-cancel-button {
  display: none;
}
@media (max-width: 1024px) {
  .input-con-box .input-con input {
    font-size: 14px;
    min-height: 38px;
  }
}
.explain-box {
  width: 90%;
  max-width: 650px;
  margin: auto;
  position: relative;
  z-index: -1;
  height: 0;
  overflow: hidden;
}
.explain-box.active{
  z-index: 5;
  height: auto;
}
.explain-box .CloseCircleOutlined {
  position: absolute;
  top: 1px;
  right: 0;
  z-index: 3;
  width: 16px;
}
.explain-box .explain-title {
  font-size: 18px;
  color: #222;
  padding:.2rem 0 .1rem;
  font-weight: bold;
  line-height: 1;
}
.explain-box .explain-title:first-child{
  padding-top: 0;
  line-height: 1;
}
.explain-box .totalNumStatus{
  display: inline-block;
  overflow: hidden;
  /*background: powderblue;*/
  position: relative;
  height:15px;
  width: 15px;
  text-align: center;
}
@keyframes down {
  0%{
    top: -100%;
  }
  100%{
    top: 100%;
  }
}
@keyframes up {
  0%{
    top: 100%;
  }
  100%{
    top: -100%;
  }
}
.explain-box .totalNumStatus .up{
  position: absolute;
  top: 100%;
  left: 0;
  animation: up .75s infinite linear;
  animation-iteration-count: 2;
  color: #00AB48;
}
.explain-box .totalNumStatus .down{
  position: absolute;
  color: #FF3C32;
  top: 100%;
  left: 0;
  animation: down .75s infinite linear;
  animation-iteration-count: 2;
}
@media (max-width: 768px) {
  .explain-box .explain-title {
    font-size: 16px;
    padding-bottom: 5px;
  }
}
.explain-box .explain-container {
  font-size: 14px;
  line-height: 1.5;
  color: #666666;
  max-height: 37vh;
  overflow: auto;
  padding-right: 2px;
}
@media (max-width: 768px) {
  .explain-box .explain-container {
    max-height: 42vh;
    padding-bottom: 80px;
  }
}
.explain-box ::-webkit-scrollbar {
  width: 12px;
}
.explain-box ::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
.explain-box ::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}
.explain-box ::-webkit-scrollbar {
  height: 12px;
}
.explain-box ::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
.explain-box ::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}
.tips {
  color: #000000;
  text-align: center;
  padding: 0.15rem 0 calc(6vh - 5px);
  line-height: 1;
  cursor: default;
}

.tips span {
  cursor: pointer;
  color: #006cd8;
}
.model-shown {
  width: 3rem;
  height: 3rem;
  margin: auto;
}
#neutral-demo{
  display: block;
  /*margin-left: 5px;*/
}
.coinThree-show {
  display: block;
}
.coinThree-hide {
  display: none;
}
.share-list {
  height: 25px;
  margin: 0 auto;
  text-align: center;
  display: flex;
}
.share-list a{
  display: inline-block;
  margin: 0 12px;
  vertical-align: top;
}
.share-list a svg{
  width: 25px;
  max-height: 25px;
}
.share-list .facebook svg,
.share-list .instagram svg,
.share-list .youtube svg,
.share-list .reddit svg{
  width: 20px;
}
.share-list .discord svg{
  width: 21px;
}
.share-list .medium{
}
.share-list .telegram svg{
  width: 20px;
}
.share-list .youtube{
}
.share-list .twitter svg{
width: 18px;
}
.share-list svg path{

  fill: #777;
}
@media (max-width: 768px) {
  .share-list {
    position: fixed;
    width: 100%;
    z-index: 10;
    padding: 20px 0;
    background: #ffffff;
    bottom: 0;
    text-align: center;
    display: block;
  }
  .share-list a{
    display: inline-block;
    margin: 0 7px;
    vertical-align: top;
  }
  .share-list a svg{
    width: 21px;
    max-height: 21px;
  }
  .share-list .facebook svg,
  .share-list .instagram svg,
  .share-list .youtube svg,
  .share-list .reddit svg{
    width: 16px;
  }
  .share-list .discord svg{
    width: 17px;
  }
  .share-list .medium{
  }
  .share-list .telegram svg{
    width: 16px;
  }
  .share-list .youtube{
  }
  .share-list .twitter svg{
    width: 14px;
  }
}
/*@media (max-width: 768px) {*/
/*  .ant-message span {*/
/*    font-size: 12px !important;*/
/*    line-height: 1;*/
/*    vertical-align: middle;*/
/*  }*/

/*  .ant-message-custom-content {*/
/*    height: auto !important;*/
/*    line-height: 1;*/
/*  }*/

/*  .ant-message .anticon-exclamation-circle {*/
/*    margin-top: -2px;*/
/*    display: inline-block;*/
/*  }*/

/*  .ant-message-notice-content {*/
/*    padding: 4px 8px 7px !important;*/
/*  }*/
/*}*/
model-viewer::part(default-progress-bar) {
  display: none;
}
.pie-container{
  margin-top: .55rem;
  height: 270px;
  max-width: 450px;
  position: relative;
}
.pie-container .chart{
  width: 100%;
  height: 100%;
}
.pie-container .desc{
  position: absolute;
  bottom: 22.5%;
  width: 47%;
  font-size: 12px;
  font-weight: bold;
  color: #222222;
  text-align: center;
}
@media screen and (max-width: 540px) {
  .pie-container .desc{
    bottom: 28%;
  }
}
.red{
}
.red a{
  color: #006cd8;
}
.anticon{
  padding-bottom: 1px;
}
.tips::selection,
.tips *::selection {
  background: transparent;
}
.ant-spin-nested-loading >div>.ant-spin .ant-spin-text{

  text-shadow: 0 1px 2px transparent;
}
.dataList {
  width: 90%;
  max-width: 650px;
  text-align: center;
  margin: 30px auto 0;
  color: #00AB48;
}
.dataList li{
  padding-bottom: 10px;
  word-wrap: break-word;
}
@keyframes dots {
  0%{
    width: 0;
  }
  33%{
    width: 5px;
  }
  66%{
    width: 10px;
  }
  100%{
    width: 15px;
  }
}
.dots-icon{
  display: inline-block;
  width: 15px;
  vertical-align: top;
}
.dots{
  vertical-align: top;
  display: inline-block;
  overflow: hidden;
  white-space:nowrap;
  width: 15px;
  animation: dots infinite 2s step-start both;
}
